<template>
  <v-col cols="12" sm="6" md="3" class="col">
    <section class="pa-2 item-card" @click="showDetails(item)">
      <img
        src="../../../assets/images/cards/land-card-bg.png"
        class="item-card-background"
        @click="showDetails(item)"
        @load="mainBgIsLoaded"
      />
      <v-skeleton-loader
        v-if="!bgIsLoaded"
        type="image"
        class="skeleton"
        min-height="220"
      ></v-skeleton-loader>
      <img />
      <v-img
        :src="item.art_portrait"
        contain
        class="item-card-image"
        @load="imgIsLoaded"
      >
        <v-skeleton-loader
          v-if="!imageIsLoaded"
          width="100%"
          height="100%"
          type="image"
          class="skeleton"
        ></v-skeleton-loader>
      </v-img>
      <div v-if="bgIsLoaded">
        <h5 class="item-card-name">
          {{ item.name.substring(0, 38) }}
        </h5>
        <h5 class="item-card-size">
          <span class="">{{ item.size }}</span>
        </h5>
        <h5 class="item-card-level">
          <span class="">#0000000</span>
        </h5>
        <h5 class="item-card-price">
          <span> {{ item.base_price.usdt.price }}$ </span>
        </h5>
        <h5 class="item-card-id">
          <span>{{ item.id }}</span>
        </h5>
      </div>

      <button class="new-shop-button" style="" @click.stop="transferNft(item)">
        <span>Transfer NFT</span>
      </button>
      <v-btn
        class="sell-button"
        @click.stop="sell(item)"
        :loading="sellLoading"
      >
        Sell
        <v-icon right small>mdi-cart</v-icon>
      </v-btn>
    </section>
    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="650">
      <DetailCard
        @close="detailDialog = false"
        @sell="sell"
        :selectedItem="selectedLand"
        :isLoading="loading"
        type="Land"
      ></DetailCard>
    </v-dialog>
    <!--detail dialog -->

    <!--transfar dialog -->
    <v-dialog v-model="transfarDialog" width="600">
      <v-card class="transfar-card black">
        <v-card-title class="grey darken-4">
          <h4 class="white--text">Transfer NFT to wallet</h4>
        </v-card-title>

        <v-card-text class="my-5">
          <v-row>
            <v-col cols="6">
              <h3 class="yellow--text">
                ID: <span class="grey--text">{{ transferInfo.id }}</span>
              </h3>
              <h3 class="yellow--text">
                Type: <span class="grey--text">{{ transferInfo.type }}</span>
              </h3>
              <div
                style="
                  width: 100%;
                  height: 20px;
                  border: 1px solid grey;
                  border-radius: 5px;
                  color: white;
                "
                class="d-flex justify-space-around my-3"
              >
                <v-icon color="white">mdi-arrow-down</v-icon>
                <h3>To</h3>
                <v-icon color="white">mdi-arrow-down</v-icon>
              </div>

              <h3 class="yellow--text">
                Wallet Address:
                <span class="grey--text">{{
                  transferInfo.wallet_address
                }}</span>
              </h3>
            </v-col>
            <v-col cols="6" class="d-flex align-center">
              <v-img
                :src="transferInfo.image"
                max-height="200"
                max-width="250"
                contain
                class="mx-auto br-5"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider dark></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="transfarDialog = false">
            Cancle
          </v-btn>
          <v-btn color="success" text @click="submitTransfer"> Transfer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--transfar dialog -->

    <!--sell dialog -->
    <v-dialog v-model="sellDialog" width="500">
      <v-card class="black">
        <v-card-title class="grey darken-4">
          <h4 class="white--text">Add To Market</h4>
        </v-card-title>

        <v-card-text class="my-5">
          <div v-if="loading" class="d-flex justify-center">
            <loading-spinner></loading-spinner>
          </div>
          <v-form ref="sellForm" v-else>
            <p class="grey--text mb-3">Please insert your information below.</p>
            <label for="price" class="white--text"
              ><span class="red--text">*</span>USDT Price</label
            >
            <v-text-field
              type="number"
              label="Price"
              :rules="rules.select"
              v-model="price"
              outlined
              dense
              dark
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider dark></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="sellDialog = false"> Cancle </v-btn>
          <v-btn
            color="success"
            text
            @click="submitSell"
            :loading="sellLoading"
          >
            Sell
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--sell dialog -->

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      color="green accent-4 "
      dense
      centerd
      shaped
      top
      transition="slide-y-transition"
      style="position: fixed"
    >
      <div class="d-flex">
        {{ message }}
        <v-spacer></v-spacer>
        <v-icon @click="snackbar = false">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      sellDialog: false,
      transfarDialog: false,
      detailDialog: false,

      loading: false,
      selectedLand: {
        parentCategory: {
          title: "",
        },
        category: {
          title: "",
        },
        sale: {
          status: "",
          base_price: "",
        },
        map: {
          art_portraite: "",
        },
      },
      transferInfo: {},
      message: "",
      snackbar: false,

      price: null,
      sellLoading: false,
      bgIsLoaded: false,
      imageIsLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["wallet_address", "rules"]),
  },
  methods: {
    ...mapActions([
      "getLandDetail",
      "transferNftToWallet",
      "getUserCoins",
      "sellItem",
    ]),
    showDetails(item) {
      this.detailDialog = true;
      this.loading = true;
      console.log(item);
      this.getLandDetail(item.id).then((result) => {
        this.selectedLand = result.result;
        console.log("selectedLand:", this.selectedLand);
        this.loading = false;
      });
    },
    transferNft(item) {
      this.transfarDialog = true;
      this.transferInfo = {
        type: "Land",
        id: item.id,
        wallet_address: this.wallet_address,
        image: item.art_portrait,
      };
    },
    submitTransfer() {
      let info = {
        nftable_id: this.transferInfo.id,
        nftable_type: this.transferInfo.type,
        wallet_address: this.transferInfo.wallet_address,
      };
      this.isLoading = true;
      this.transferNftToWallet(info)
        .then((result) => {
          this.message = result.data.message;
        })
        .finally(() => {
          this.transfarDialog = false;
          this.snackbar = true;
          this.$emit("fetchLands");
          this.isLoading = false;
        });
    },
    sell(item) {
      console.log(item);
      this.selectedLand = item;
      this.sellDialog = true;
      if (this.$refs.sellForm) {
        this.$refs.sellForm.reset();
      }
    },
    submitSell() {
      let info = {
        coin_id: 1,
        seller_land_id: this.selectedLand.id,
        amount: 1,
        price: {
          usdt: { coin: "usdt", price: this.price, coin_id: 1 },
          ecg: { coin: "ecg", price: 0, coin_id: 2 },
        },
        saleable_type: "Land",
        saleable_id: this.selectedLand.id,
      };
      if (this.$refs.sellForm.validate()) {
        this.sellLoading = true;
        this.sellItem(info)
          .then((result) => {
            console.log(result);

            this.sellDialog = false;
            this.detailDialog = false;
            this.message = result.data.message;
            this.snackbar = true;
            this.$emit("fetchLands");

            this.$refs.sellForm.reset();
            this.$refs.sellForm.resetValidation();
          })
          .finally(() => {
            this.sellLoading = false;
          });
      }
    },
    mainBgIsLoaded() {
      console.log("loaded");
      this.bgIsLoaded = true;
    },
    imgIsLoaded() {
      this.imageIsLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  transition: all 0.3s ease;
  margin-bottom: 50px;
  border-radius: 25px;
  &:hover {
    // .sell-button {
    //   transform: translateX(-50%) translateY(-110px) !important;
    //   opacity: 1 !important;
    // }
  }
  .item-card {
    position: relative;
    // background: red;
    .item-card-background {
      width: 100%;
      cursor: pointer;
      position: relative;
      .skeleton {
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }
    .item-card-image {
      position: absolute;
      top: 39.5%;
      left: 11.5%;
      right: 11.5%;
      pointer-events: none;
      border-radius: 5px;
      transform: scaleY(1.04);
      .skeleton {
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }
    .new-shop-button {
      position: absolute;
      width: 40%;
      height: 15%;
      background: url("../../../assets/images/buttons/buy-button-background.png")
        no-repeat !important;
      background-size: cover !important;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      &:hover {
        animation: shake 0.4s linear 1;
      }
      span {
        font-size: 10px;
        font-weight: 600;
        color: #ffd600;
        text-shadow: 0px 0px 4px #ffd600;
      }
    }
    .sell-button {
      position: absolute;
      bottom: -45px;
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(120, 0, 0, 0.872);
      border: 2px solid var(--cancle-color);
      color: var(--cancle-color);
      transition: all 0.5s ease;
      opacity: 1;
      &:hover {
        background: var(--cancle-color);
        color: red;
        box-shadow: 0 0 10px 5px red;
        letter-spacing: 10px;
        font-weight: bolder;
      }
    }
    .item-card-name {
      position: absolute;
      top: 23%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      font-weight: 9000;
      color: #000e44;
      text-align: center;
      line-height: 12px;
    }
    .item-card-size {
      position: absolute;
      top: 29%;
      right: 25%;
      span {
        color: white;
        font-size: 9px;
      }
    }
    .item-card-level {
      position: absolute;
      top: 29%;
      left: 25%;
      span {
        color: white;
        font-size: 9px;
      }
    }
    .item-card-price {
      position: absolute;
      bottom: 10%;
      left: 15%;
      span {
        color: #000e44;
        font-size: 12px;
      }
    }
    .item-card-id {
      position: absolute;
      bottom: 10%;
      right: 14%;
    }
  }
  .v-skeleton-loader {
    z-index: 1;
  }
}
@keyframes shake {
  0% {
    transform: translateX(-47%);
  }
  25% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(-47%);
  }
  75% {
    transform: translateX(-53%);
  }
  100% {
    transform: translateX(-47%);
  }
}
</style>
